import { createSlice } from "@reduxjs/toolkit";

export const CartSlice = createSlice({
    name: "cart",
    initialState: [],
    reducers:{
        add: (state, action) => {
            const existingItem = state.find(
                (item) => item.id === action.payload.id && item.size === action.payload.size
            );

            if (existingItem) {
                // Если элемент уже существует, увеличиваем его количество на 1
                existingItem.quantity += 1;
            } else {
                // Если элемента нет, добавляем его с количеством 1
                state.push({ ...action.payload, quantity: 1 });
            }
        },
        remove:(state, action) => {
            return state.filter((item) => item.id !== action.payload);
        },
        remove_size_id: (state, action) => {
            console.log(action.payload.id, action.payload.size);
            return state.filter(
                (item) => !(item.id === action.payload.id && item.size === action.payload.size)
            );
        },
    }
});

export const {add, remove,remove_size_id} = CartSlice.actions;
export default CartSlice.reducer;