import React from 'react'
import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { click } from '@testing-library/user-event/dist/click';
import DetailProduct from '../components/DetailProduct';

const Product = ({item}) => {

    const [open, setOpen] = useState(false)

    const openCard = () => {
        if (!open) {
            setOpen(true);
            return;
        }
    }
    const close = () => {
        setOpen(false);
    }

   

  return (
        <div >
         <div className="thing mt-8 z-0 cursor-pointer"  onClick={()=>openCard()}>
            <div className="">
              <Swiper pagination={true} modules={[Pagination]} className="mySwiper product-image-list-courusel" >
                    {
                        item.images_list.slice(0,5).map((image) => (
                            <SwiperSlide 
                            key={image}
                            >
                                <div className='coursel-div-list' 
                                    style={{backgroundImage: `url(${image})`}}>
                                </div>
                                {/* <img className="carusel-my" src={image} alt=""/> */}
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <div className="thing_data cursor-pointer">
               <div className="thing_data-wrapper">
                  <h3 className="thing_data__title max-70">{item.name}</h3>
                  <p className="thing_data__description"> {item.prewiev_text}</p>
               </div>
               <div className="thing_data-price w-content">{item.price.toLocaleString('ru')} руб</div>
            </div>
                {
                open ? (<DetailProduct item={item} onClose={()=>close()} />) : (null)
                }
            </div>
            

         </div>
         
      
  )
}

export default Product