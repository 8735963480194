import React ,{useState, useEffect, useMemo} from 'react'
import {useSelector,useDispatch} from 'react-redux';
import CartItem from '../components/CartItem';
import { Link } from 'react-router-dom';

const Cart = () => {

  const {cart} = useSelector( (state) => state);
  const [userData, setUserData] = useState({
    name: '',
    phone: '',
    email: '',
    address: ''
});

  const itemsTotal = useMemo( () => cart.reduce( (acc, curr) => acc + curr.quantity, 0), [cart]);
  const totalAmount = useMemo( () => cart.reduce( (acc, curr) => acc + curr.price*curr.quantity, 0), [cart]);
  const [loading, setLoading] = useState(false);
  const dataIsOk = useMemo(() => {
    return Object.values(userData).every(field => field.length >= 3);
}, [userData]);
   
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({
        ...userData,
        [name]: value // обновляем соответствующее поле в userData
    });
};


  const LetsPayment = () => {
    setLoading(true)
    const headers = new Headers();
    headers.append('Authorization',  '');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    
      fetch('https://theosnovatel.com/api/shoping-payment',  {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ items: cart, amount: totalAmount,itemsTotal:itemsTotal, userData: userData})
        })
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          window.location.href = data.url_pay
          setLoading(false)
        })
  }


  return (
  <>
    {
      cart.length > 0 ? 
      (
        <section className="shopping-cart ">
          <div className="products-list">

          {
            cart.map((item,index) => {
              return <CartItem key={`${item.id}-${index}`} item={item} itemIndex={index} />
            })
          }

            
           
          </div>
          <div className="delivery-wrapper">
            <div className="delivery-recipient">
                <div className="delivery__title">Получатель</div>
                <input  className="delivery-recipient__input recipient-name" placeholder="Ваше Имя" type="text" name="name" onChange={handleChange} value={userData.name} />
                <input className="delivery-recipient__input recipient-phone" placeholder="Телефон / Telegram / WA" type="text" name="phone" onChange={handleChange} value={userData.phone} />
                <input className="delivery-recipient__input recipient-phone" placeholder="E-Mail" type="text" name="email" onChange={handleChange} value={userData.email}/>
                <input className="delivery-recipient__input recipient-phone" placeholder="Адрес" type="text" name="address" onChange={handleChange} value={userData.address}/>
            </div>
        </div>
          <div className="order-wrapper">
            <div className="order_price">
                <div className="order_price__text">Стоимость</div>
                <div className="order_price__number">{totalAmount.toLocaleString('ru')} руб</div>
            </div>
            <div className="order_delivery">
                <div className="order_delivery__text">Доставка x {itemsTotal} шт</div>
                <div className="order_delivery__number">0 руб</div>
            </div>
            <div className="order_total">
                <div className="order_total__text">Всего</div>
                <div className="order_total__number"> {totalAmount.toLocaleString('ru')} руб</div>
            </div>

            <div className='oferta'>
              ✅ При оформлении заказа, вы соглашаетесь с <a className='link' href="https://theosnovatel.com/static-files/offert.pdf" target="_blank">публичной офертой</a> и <a className='link' href="https://theosnovatel.com/static-files/conf.pdf" target="_blank">политикой конфиденциальности</a>
            </div>

           
            { loading ? (
               <button className="btn-buy main-btn " >Обработка ...</button>
            ) : (
              !dataIsOk ? (
                <button className="btn-buy main-btn  red-boarder" >
                  <div>
                    <span>Оплатить заказ</span> 
                    <div className='btn-comment'>заполните информацию получателя, чтобы завершить заказ</div>
                  </div>
                </button>
              ) : (

               <button className="btn-buy main-btn" onClick={()=>LetsPayment()}>Оплатить заказ</button>
              )
              )}
            <Link to={"/"}> 
           
              <button className="btn-buy main-btn-back mt-2">В Каталог</button>
            </Link>
          </div>
      </section>
      
      ) :
      (
      <div className='min-h-[80vh] flex flex-col items-center justify-center'>
        <div className='mb-5 text-center text-gray-700 font-semibold text-xl mb-2'>
           <p>Корзина Пустая,</p> 
           <p>вернитесь в каталог</p>
          </div>
        <Link to={"/"}>
            <button className="btn-buy main-btn-back mt-2 px-10 tracking-wider">В Каталог</button>
          </Link>
      </div>
      )
    }
  </>
  )
}
  
export default Cart