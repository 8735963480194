import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import {MdDelete} from "react-icons/md"
import { remove_size_id } from '../redux/Slice/CartSlice';
import { useState } from 'react';

const CartItem = ({item, itemIndex}) => {
    
    const dispatch = useDispatch ();

    const removeFromCart = () => {
        dispatch(remove_size_id(item));
        toast.error("Удален из корзины");
     }
     
    
    return (
        <div className="card-product">
                <div className="card-product-wrapper">
                  <div className="products-item__img">
                      <img  src={item.images_list[0]} />
                  </div>
                  <div className="products-item-data">
                      <div className="products-item-data__name">{item.title}</div>
                      <div className="products-item-data__price">{item.price.toLocaleString('ru')} руб</div>
                      {
                        item.size === "no" ? null : (
                            <div className="products-item-data__size">Размер: {item.size.toUpperCase()}</div>
                        )
                      }
                      <div className="products-item-data__size">Кол-во: {item.quantity} шт.</div>
                      <div className="product_count">
                      <button className="product_btn__dec" onClick={removeFromCart}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M18.8499 9.14001L18.1999 19.21C18.0899 20.78 17.9999 22 15.2099 22H8.7899C5.9999 22 5.9099 20.78 5.7999 19.21L5.1499 9.14001" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.3301 16.5H13.6601" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.5 12.5H14.5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                        </button>

                        {/* <button className="product_btn__dec"><svg width="14" height="4" viewBox="0 0 14 4" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M1.58333 3.08341H12.4167C13.0147 3.08341 13.5 2.59808 13.5 2.00008C13.5 1.40208 13.0147 0.916748 12.4167 0.916748H1.58333C0.985333 0.916748 0.5 1.40208 0.5 2.00008C0.5 2.59808 0.985333 3.08341 1.58333 3.08341Z"
                                  fill="#393F48" />
                            </svg>
                        </button> */}
                        {/* <span className="product_count__num">1</span> */}
                        {/* <button className="product_btn__inc"><svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                  d="M1.58333 8.08341H12.4167C13.0147 8.08341 13.5 7.59808 13.5 7.00008C13.5 6.40208 13.0147 5.91675 12.4167 5.91675H1.58333C0.985333 5.91675 0.5 6.40208 0.5 7.00008C0.5 7.59808 0.985333 8.08341 1.58333 8.08341Z"
                                  fill="#393F48" />
                              <path fillRule="evenodd" clipRule="evenodd"
                                  d="M8.08341 12.4167V1.58333C8.08341 0.985333 7.59808 0.5 7.00008 0.5C6.40208 0.5 5.91675 0.985333 5.91675 1.58333V12.4167C5.91675 13.0147 6.40208 13.5 7.00008 13.5C7.59808 13.5 8.08341 13.0147 8.08341 12.4167Z"
                                  fill="#393F48" />
                            </svg>
                        </button> */}
                      </div>
                  </div>
                </div>
                <button className="product-delete"><img src="../images/delete_icon.svg" alt=""/></button>
            </div>
            
       
    )
}

export default CartItem;